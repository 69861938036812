import { useTranslation } from "react-i18next";
import styles from "./external_notifications.css";
import { GreenhouseLogo } from "./greenhouse_logo";
import { COLOR } from "~/types/ui";
import { useEffect, useState } from "react";

export const links = () => [{ rel: "stylesheet", href: styles }];

type Props = {
  urlToken: string;
  companyName: string;
};

const ExternalNotifications = (props: Props) => {
  const { t } = useTranslation("board");
  const { urlToken, companyName } = props;
  const [jobSeekersUrl, setJobSeekersUrl] = useState("");

  useEffect(() => {
    setJobSeekersUrl(`${window.ENV.JOB_SEEKERS_URL}/users/sign_in?job_board=${urlToken}`);
  }, [urlToken]);

  return (
    <div className={"external_notifications"}>
      <div className={"external_notifications__header"}>
        <div className={"external_notifications__header__title"}>
          <div>{t("external_notifications.notify_me")}</div>
        </div>
        <div className={"external_notifications__header__description"}>
          {t("external_notifications.subscribe", { companyName: companyName })}
        </div>
      </div>
      <a
        className="external_notifications__button"
        target="_blank"
        href={jobSeekersUrl}
        rel="noreferrer">

        {t("external_notifications.sign_up")}
      </a>
      <div className={"external_notifications__footer"}>
        <GreenhouseLogo height={30} width={100} color={COLOR.darkGreen} />
      </div>
    </div>);

};

export default ExternalNotifications;